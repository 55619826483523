import { SearchProvider } from '../../../../createCampign.typs';
import { ActionsBar, ButtonType, IconButton, LockPositionType, TableNewColDef } from '@ftbpro/mm-admin-ui-components';
import { DeleteIcon } from '@ftbpro/mm-admin-assets';
import { Configuration } from '../../../../../../../../configurations/configurations.types';
import { validateDefaultBid, validateDefaultDailyBudget, validateFreeTextName } from '../../campaignsList.validations';

interface getColumnsDefinitionProps {
  isExternalUser: boolean;
  tonicCampaignsList: { value: string, id: string }[];
  onDeleteClick: (id: number) => void;
  onCellValueChanged: (params: any) => void;
  userDefaultConfiguration: Configuration;
  storedColumns: string[];
  isSearchCampaign: boolean;
  countries: Record<string, string>;
}

const setHideColumns = (columns: Partial<TableNewColDef>[], storedColumns: string[]) => {
  return columns.map(column => ({
    ...column,
    hide: storedColumns.length > 0 ? !storedColumns.includes(column.headerName || column.field || '') : column.hide,
  }));
};

export const getColumnsDefinition = ({ isExternalUser, tonicCampaignsList, onDeleteClick, onCellValueChanged, userDefaultConfiguration, isSearchCampaign, storedColumns, countries }: getColumnsDefinitionProps) => {
  const columns = [
    {
      field: 'actions',
      headerName: '',
      maxWidth: 48,
      resizable: false,
      suppressHeaderMenuButton: true,
      lockPosition: LockPositionType.right,
      pinned: LockPositionType.right,
      suppressMovable: true,
      lockPinned: true,
      enableRowGroup: false,
      enablePivot: false,
      cellRenderer: (params: any) =>
        <ActionsBar
          absolutelyPositioned={true}
          elements={[
            {
              tooltipContent: 'Delete',
              render: () => (
                <IconButton
                  type={ButtonType.Secondary}
                  icon={DeleteIcon}
                  onClick={() => onDeleteClick(params.data.id as number)}
                />
              ),
            }]}
        />,
    },
    {
      headerName: '#',
      valueGetter: (params: any) => params.node.rowIndex + 1,
      width: 50,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      hide: false,
      filter: 'agNumberColumnFilter',
    },
    {
      headerName: 'Name',
      field: 'freeTextName',
      filter: 'agTextColumnFilter',
      width: 180,
      editable: true,
      cellStyle: function (params: any) {
        if (params.value) {
          if (!validateFreeTextName(params.value)) {
            return { color: 'white', background: '#ff4c6a' };
          } else {
            return { color: 'inherit', background: 'inherit' };
          }
        }
      },
      onCellValueChanged: onCellValueChanged,
    },
    {
      headerName: 'Device',
      field: 'device',
      width: 90,
      filter: 'agTextColumnFilter',
      filterParams: { newRowsAction: 'keep' },
    },
    {
      headerName: 'OS',
      field: 'os',
      filter: 'agTextColumnFilter',
      width: 90,
      filterParams: { newRowsAction: 'keep' },
    },
    {
      headerName: 'Browsers',
      field: 'browser',
      width: 90,
      filter: 'agTextColumnFilter',
      filterParams: { newRowsAction: 'keep' },
    },
    {
      headerName: 'Country',
      field: 'country',
      width: 70,
      filter: 'agTextColumnFilter',
      filterParams: { newRowsAction: 'keep' },
      valueFormatter: (params: any) => countries[params.value],
    },
    {
      headerName: 'Objective',
      field: 'objective',
      width: 100,
      filter: 'agTextColumnFilter',
      filterParams: { newRowsAction: 'keep' },
    },
    {
      headerName: 'CPC',
      field: 'defaultBid',
      filter: 'agNumberColumnFilter',
      width: 90,
      editable: (params: any) => !isExternalUser && !(params.data.bidStrategy === 'LOWEST_COST_WITHOUT_CAP'),
      valueFormatter: (params: any) => {
        if (params.data.bidStrategy === 'LOWEST_COST_WITHOUT_CAP') {
          return 0;
        }
        return params.value ? params.value : userDefaultConfiguration.defaultBid;
      },
      cellStyle: (params: any) => {
        if (params.value) {
          if (!validateDefaultBid(params.value, params?.date?.maxBid || userDefaultConfiguration.maxBid)) {
            return { color: 'white', background: 'red' };
          } else {
            return { color: 'inherit', background: 'inherit' };
          }
        } else {
          return { color: '#B2B2B2', background: 'inherit' };
        }
      },
      filterParams: { newRowsAction: 'keep' },
      onCellValueChanged: onCellValueChanged,
    },
    {
      headerName: 'Max Bid',
      field: 'maxBid',
      filter: 'agNumberColumnFilter',
      width: 100,
      editable: false,
      valueFormatter: (params: any) => params.value ? params.value : userDefaultConfiguration.maxBid,
      cellStyle: (params: any) => {
        if (params.value) {
          if (!validateDefaultDailyBudget(params.value)) {
            return { color: 'white', background: '#ff4c6a' };
          } else {
            return { color: 'inherit', background: 'inherit' };
          }
        } else {
          return { color: '#B2B2B2', background: 'inherit' };
        }
      },
    },
    {
      headerName: 'Daily Budget',
      field: 'defaultDailyBudget',
      filter: 'agNumberColumnFilter',
      width: 90,
      editable: !isExternalUser,
      cellStyle: (params: any) => {
        if (params.value) {
          if (!validateDefaultDailyBudget(params.value)) {
            return { color: 'white', background: '#ff4c6a' };
          } else {
            return { color: 'inherit', background: 'inherit' };
          }
        } else {
          return { color: '#B2B2B2', background: 'inherit' };
        }
      },
      valueFormatter: (params: any) => params.value ? params.value : userDefaultConfiguration.defaultDailyBudget,
      onCellValueChanged: onCellValueChanged,
    },
    {
      headerName: 'Custom Pixel',
      field: 'customPixel',
      filter: 'agTextColumnFilter',
      width: 180,
      filterParams: { newRowsAction: 'keep' },
    },
    {
      headerName: 'Conversion Events',
      field: 'conversionEvent',
      filter: 'agTextColumnFilter',
      width: 100,
    },
    {
      headerName: 'Interests',
      field: 'interest',
      filter: 'agTextColumnFilter',
      cellRenderer: (params: any) => {
        let interestDisplay = '';
        if (params.value != null && params.value.category) {
          interestDisplay += params.value.category + ' > ';
        }
        if (params.value != null && params.value.name) {
          interestDisplay += params.value.name;
        } else {
          interestDisplay = '';
        }
        return interestDisplay;
      },
      width: 100,
    },
    {
      headerName: 'Ad Delivery Optimization',
      field: 'optimizationGoal',
      filter: 'agTextColumnFilter',
      hide: true,
      width: 100,
    },
    {
      headerName: 'ROAS',
      field: 'roas',
      filter: 'agNumberColumnFilter',
      hide: true,
      width: 100,
    },
    {
      headerName: 'Bid Strategy',
      field: 'bidStrategy',
      filter: 'agTextColumnFilter',
      hide: true,
      width: 100,
    },
    {
      headerName: 'Gender',
      field: 'gender',
      filter: 'agTextColumnFilter',
      hide: true,
      width: 100,
    },
    {
      headerName: 'Age',
      field: 'age',
      filter: 'agTextColumnFilter',
      hide: true,
      width: 100,
    },
    {
      headerName: 'Audiences',
      field: 'audiences.name',
      filter: 'agTextColumnFilter',
      hide: true,
      width: 100,
    },
    {
      headerName: 'Paused Campaign',
      field: 'pausedCampaign',
      filter: 'agTextColumnFilter',
      cellRenderer: (params: any) => params.value ? 'v' : '',
      width: 300,
      hide: true,
    },
    {
      headerName: 'Target Language',
      field: 'languageSet',
      filter: 'agTextColumnFilter',
      width: 300,
      hide: true,
      cellRenderer: (params: any) => params.value ? 'v' : '',
    },
    {
      headerName: 'Start on (EST)',
      field: 'startOn',
      filter: 'agTextColumnFilter',
      hide: true,
      width: 100,
    },
    {
      headerName: 'Dynamic Creative',
      field: 'dynamicCreative',
      filter: 'agTextColumnFilter',
      valueFormatter: (params: any) => params.value ? 'v' : '',
      width: 300,
      hide: true,
    },
    {
      headerName: 'Upload to Instagram',
      field: 'uploadToInstagram',
      filter: 'agTextColumnFilter',
      valueFormatter: (params: any) => params.value ? 'v' : '',
      width: 300,
      hide: true,
    },
    {
      headerName: 'Tonic Campaign',
      field: 'search.tonic',
      filter: 'agNumberColumnFilter',
      width: 90,
      hide: !isSearchCampaign,
      valueFormatter: (params: any) => params.data.isSearch && params.value.campaignId ? tonicCampaignsList?.filter(campaign => campaign.id.toString() === params.value.campaignId)[0].value : '',
    },
    {
      headerName: 'Campaign Offer',
      field: 'campaignOffer',
      filter: 'agNumberColumnFilter',
      width: 90,
      hide: !isSearchCampaign,
      editable: (params: any) => params.data.isSearch && (params.data.searchProvider === SearchProvider.ExploreAds),
      valueFormatter: (params: any) => params.data.isSearch && params.data.searchProvider === SearchProvider.ExploreAds ? params.value : '',
      onCellValueChanged: onCellValueChanged,
    },
    {
      headerName: 'Identifier',
      field: 'identifier',
      filter: 'agNumberColumnFilter',
      width: 90,
      hide: !isSearchCampaign,
      editable: (params: any) => params.data.isSearch && (params.data.searchProvider === SearchProvider.ExploreAds || params.data.searchProvider === SearchProvider.AFS),
      valueFormatter: (params: any) => params.data.isSearch && (params.data.searchProvider === SearchProvider.ExploreAds || params.data.searchProvider === SearchProvider.AFS) ? params.value : '',
      onCellValueChanged: onCellValueChanged,
    },
  ];
  return setHideColumns(columns, storedColumns);
};

