/** @jsxImportSource @emotion/react */
import { CssTooltip, FontWeight, RadioGroup, Text, TOOLTIP_POSITIONS } from "@ftbpro/mm-admin-ui-components"
import { DuplicateOption } from "../../../../../../createCampign.typs"
import { Card } from "../../../Card/Card"
import { useRecoilState, useResetRecoilState, useSetRecoilState } from "recoil"
import { duplicationTypeState } from "./DuplicationType.state"
import { css } from "@emotion/react"
import { targetState } from "../../../Target/targetState/target.atom"
import { campaignsDraftTriggeredState, campaignsTableState } from "../../../../../../createCampaignState/createCampaign.selector"

const duplicationTypeItems = [
  {
    label: 'New Targeting',
    value: DuplicateOption.NewTargeting,
  },
  {
    label: 'From Origin Adsets',
    value: DuplicateOption.OriginAdsets
  }
]

const styles = {
  titleContainer: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '4px'
  })
}

export const DuplicationType = () => {
  const [selectedType, setSelectedType] = useRecoilState(duplicationTypeState);
  const setCampaignsListState = useSetRecoilState(campaignsTableState);
  const setTriggerState = useSetRecoilState(campaignsDraftTriggeredState);
  const resetTarget = useResetRecoilState(targetState);

  const clearCampaignsTable = () => {
    setTriggerState({ loading: false, triggered: false });
    setCampaignsListState((prevState) => ({ ...prevState, campaignsList: [] }));
  };

  const onDuplicationTypeChange = (value: string) => {
    resetTarget();
    setSelectedType(value as DuplicateOption);
    if(value === DuplicateOption.OriginAdsets){
      clearCampaignsTable()
    }
  }

  return (
    <Card>
      <div css={styles.titleContainer}>
        <Text type={Text.TEXT_TYPES.PARAGRAPH_L} fontWeight={FontWeight.Medium} >Duplication Type</Text>
        <CssTooltip
          content="Select how to set targeting: create new settings manually or with a template, or base them on the original adsets"
          multiline
          position={TOOLTIP_POSITIONS.BOTTOM}
          variables={{
            tooltipMultilineWidth: "250px"
          }}
        />
      </div>
      <RadioGroup items={duplicationTypeItems}
        onCheckChanged={onDuplicationTypeChange}
        defaultCheckedValue={selectedType}
      />
    </Card>
  )
}
