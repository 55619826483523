import { FontWeight, RadioGroup, Text } from "@ftbpro/mm-admin-ui-components"
import { Card } from "../../../../../Card/Card"
import { useRecoilState, useRecoilValue } from "recoil"
import { conversionsTypeState } from "./DuplicateConversions.state"
import { ConversionsType } from "../../../../../../../../createCampign.typs"
import { ConversionsEvent } from "../ConversionsEvent/ConversionsEvent"
import { ConversionShift } from "../ConversionShift/ConversionShift"
import { searchState } from "../../../SearchCampaignContainer/searchCampaign.atom"

const conversionsItems = [
  { label: "Event", value: ConversionsType.Event },
  { label: "Shift", value: ConversionsType.Shift }
]
export const DuplicateConversionsEvent = () => {
  const [conversionsType, setConversionsType] = useRecoilState(conversionsTypeState);
  const { isSearchCampaign } = useRecoilValue(searchState);

  const onConversionTypeChanged = (value: string) => {

    setConversionsType(value);
  }

  return (
    !isSearchCampaign ?
    <Card>
      <Text type={Text.TEXT_TYPES.PARAGRAPH_L} fontWeight={FontWeight.Medium}>Conversions Event</Text>
      <RadioGroup
        items={conversionsItems}
        onCheckChanged={onConversionTypeChanged}
        checkedValue={conversionsType || ConversionsType.Event}
        orientation={RadioGroup.ORIENTATION.HORIZONTAL}
      />
      {
       conversionsType === ConversionsType.Event ?
       <ConversionsEvent multiSelection={false}/>
       :
       <ConversionShift/>
      }
    </Card>
    :
    null
  )
}
