import { CampaignListItem, ServerSearch } from '../../createCampaignState/createCampaign.selector';
import { Scheduled, SearchProvider } from '../../createCampign.typs';
import moment_tz from 'moment-timezone';
import { CampaignTarget } from '../SettingsAndTarget/components/Target/targetState/target.atom';
import { UNSELECT } from '../../createCampaign.strings';

type DomainsAndAccountsMap = {
  [key: string]: string[];
};

type CustomDate = {
  date: Date | null;
  hour: string;
  minutes: string;
};


const formatDomainsAccountsForServer = (accountsDomains: { domain: string, accounts: string[] }[]) => {
  return accountsDomains.reduce<DomainsAndAccountsMap>((result, item) => {
    result[item.domain] = item.accounts;
    return result;
  }, {} as DomainsAndAccountsMap);
};

const formatSearchForServer = (searchProvider: SearchProvider, search: ServerSearch, identifier: string | undefined, campaignOffer: string | undefined) => {
  switch (searchProvider) {
    case SearchProvider.Tonic:
      return {
        searchProvider: SearchProvider.Tonic,
        tonic: {
          campaignId: search?.tonic?.campaignId as string
        }
      };
    case SearchProvider.ExploreAds:
      return {
        searchProvider: SearchProvider.ExploreAds,
        exploreAds: {
          campaignOffer: campaignOffer as string,
          identifier: identifier as string
        }
      };
    case SearchProvider.AFS:
      return {
        searchProvider: SearchProvider.AFS,
        googleDirect: {
          identifier: identifier as string
        }
      };
    default:
      return {};
  }
};

const formatCampaignsListForServer = (campaignsList: CampaignListItem[]) => {
  return campaignsList.map(({ checksum, searchProvider, campaignOffer, identifier, ...rest }) => ({
    ...rest,
    search: {
      ...rest.search,
      ...formatSearchForServer(searchProvider as SearchProvider, rest.search, identifier, campaignOffer),
    }
  }));
};

const getTomorrow00EstDate = () => {
  const dateString = moment_tz.tz('America/New_York').add(1, 'day').format('YYYY-MM-DD') + ' 00:00';
  return moment_tz.tz(dateString, 'YYYY-MM-DD HH:mm', 'America/New_York').utc().toDate();
};

const getCustomDate = (customDate?: { date: Date | null, hour: string, minutes: string }) => {
  if (!customDate?.date || !customDate.hour || !customDate.minutes) {
    return null;
  }
  const dateString = moment_tz(customDate.date).format('YYYY-MM-DD') + ' ' + customDate.hour + ':' + customDate.minutes;
  return moment_tz.tz(dateString, 'YYYY-MM-DD HH:mm', 'America/New_York').utc().toDate();
};

function getStartOnDate(scheduledValue: Scheduled, customDate?: { date: Date | null, hour: string, minutes: string }) {
  switch (scheduledValue) {
    case Scheduled.Custom:
      return getCustomDate(customDate);
    case Scheduled.Tomorrow00EST:
      return getTomorrow00EstDate();
    default:
      return null;
  }
}

export const formatSubmitDataForServer = (campaignsList: CampaignListItem[], articleGroup: string = '', articleId: string = '', accountsDomains: { domain: string, accounts: string[] }[], scheduledCampaign: Scheduled, isPausedCampaign: boolean, customDate?: CustomDate) => {
  const domainsAndAccounts = formatDomainsAccountsForServer(accountsDomains);
  const formattedCampaigns = formatCampaignsListForServer(campaignsList);

  return {
    drafts: formattedCampaigns,
    articleGroup,
    domainsAndAccounts,
    startOn: getStartOnDate(scheduledCampaign, customDate),
    pausedCampaign: isPausedCampaign,
    articleId,
  };
};

export const formatDuplicateSubmitDataForServer = (campaignsList: CampaignListItem[], duplicateCampaigns: string[], useOriginalPostID: boolean, scheduledCampaign: Scheduled, isPausedCampaign: boolean, customDate?: CustomDate) => {
  const formattedCampaigns = formatCampaignsListForServer(campaignsList);

  return {
    drafts: formattedCampaigns,
    originalCampaigns: duplicateCampaigns,
    startOn: getStartOnDate(scheduledCampaign, customDate),
    pausedCampaign: isPausedCampaign,
    useOriginalPostId: useOriginalPostID,
  };
};

export const formatOriginDuplicateSubmitDataForServer = (target: CampaignTarget, duplicateCampaigns: string[], useOriginalPostID: boolean, scheduledCampaign: Scheduled, isPausedCampaign: boolean, customDate?: CustomDate) => {
  const getValue = (value?: any) => {
    const isEmpty = !value ||  value?.length === 0 ||  value === UNSELECT;
    if(isEmpty){
      return null;
    }
    return value;
  }

  return {
    bidStrategy: getValue(target.bidStrategy?.[0]),
    bid: getValue(target.bidAmount?.[0]),
    conversionEvent: getValue(target.conversionsEvent?.[0]),
    country: getValue(target.countries?.[0]?.id),
    originalCampaigns: duplicateCampaigns,
    startOn: getStartOnDate(scheduledCampaign, customDate),
    pausedCampaign: isPausedCampaign,
    useOriginalPostId: useOriginalPostID,
    conversionShift: getValue(target.conversionShift)
  };
};


