import { useRecoilValue } from "recoil"
import { duplicationTypeState } from "./components/DuplicationType/DuplicationType.state"
import { DuplicateOption } from "../../../../createCampign.typs"
import { DuplicationType } from "./components/DuplicationType/DuplicationType";
import { Target } from "../Target/Target";
import { BidAmount } from "../Target/components/BidAmount/BidAmount";
import { TargetCountries } from "../Target/components/TargetCountries/TargetCountries";
import { DuplicateConversionsEvent } from "../Target/components/CampaignSetup/components/DuplicateConversions/DuplicateConversions";
import { CampaignBidStrategy } from "../Target/components/CampaignSetup/components/CampaignBidStrategy/CampaignBidStrategy";

export const DuplicationTarget = () => {
  const isOriginDuplicate = useRecoilValue(duplicationTypeState) === DuplicateOption.OriginAdsets;

  return (
    <>
      <DuplicationType />
      {
        isOriginDuplicate ?
          <>
            <DuplicateConversionsEvent />
            <CampaignBidStrategy multiSelection={false} showDefaultValue={false} cardWrapper={true}/>
            <BidAmount multiSelection={false} />
            <TargetCountries multiSelection={false} required={false} />
          </>
          :
          <Target />
      }

    </>
  )
}
