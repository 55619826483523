/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { Button, ButtonType, CssTooltip, TOOLTIP_POSITIONS, Text, SNACK_BAR_STATUSES } from '@ftbpro/mm-admin-ui-components';
import { css } from "@emotion/react";
import { CampaignsListTable } from './components/CampaignsListTable/CampaignsListTable';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { campaignsDraftTriggeredState, campaignsTableState } from '../../createCampaignState/createCampaign.selector';
import { userConfiguration } from '../../createCampaignState/user.selector';
import { validateCampaigns } from './campaignsList.validations';
import { TooltipContent } from '../../../../../../../components/TooltipContent/TooltipContent';
import { CampaignsServiceProvider } from '../../../../../../../services/campaigns/CampaignsServiceProvider';
import { targetState } from '../SettingsAndTarget/components/Target/targetState/target.atom';
import { useParams } from 'react-router-dom';
import { settingsState } from '../SettingsAndTarget/components/Settings/settingsState/settings.atom';
import { useNavigateToPage } from '../../../../../../../hooks/useNavigate';
import { formatDuplicateSubmitDataForServer, formatOriginDuplicateSubmitDataForServer, formatSubmitDataForServer } from './campaignsList.foramtter';
import { duplicateSettingsState } from '../SettingsAndTarget/components/Settings/components/DuplicateCampaignsSettings/duplicateSettings.state';
import { campaignData } from '../SettingsAndTarget/components/Target/targetState/target.selector';
import { campaignMessageState } from '../CreateCampaignMessage/createCampaignMessage.atom';
import { getUserDetails } from '../../../../../../../core/utils/user.utils';
import { duplicationTypeState, isDuplicateState } from '../SettingsAndTarget/components/DuplicationTarget/components/DuplicationType/DuplicationType.state';
import { DuplicateOption } from '../../createCampign.typs';

const styles = {
  container: {
    flexGrow: 3,
  },
  buttonContainer: css({
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#ffffff",
    alignItems: "center",
    justifyContent: "right",
    padding: "16px 20px",
    gap: '8px',
    height: '52px',
  }),
  total: {
    marginRight: "8px",
  },
  tooltip: {
    zIndex: 4,
  }
}

const variables = {
  button: {
    width: "100px",
  }
}

export const CampaignsListContainer = () => {
  const setTriggerState = useSetRecoilState(campaignsDraftTriggeredState);
  const target = useRecoilValue(targetState);
  const settings = useRecoilValue(settingsState);
  const { accountsDomains, scheduledCampaign, customDate, isPausedCampaign } = settings;
  const { isDynamicCreative } = target;
  const isDuplicate = useRecoilValue(isDuplicateState);
  const userDefaultConfiguration = useRecoilValueLoadable(userConfiguration).contents;
  const [campaignsListState, setCampaignsListState] = useRecoilState(campaignsTableState);
  const { campaignsList } = campaignsListState;
  const [errors, setErrors] = useState<string[]>([]);
  const setMessage = useSetRecoilState(campaignMessageState);
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [totalAdsets, setTotalAdsets] = useState(0);
  const { group_name: groupName } = useParams();
  const data = useRecoilValueLoadable(campaignData);
  const { selectedDuplicateCampaigns, useOriginalPostID } = useRecoilValue(duplicateSettingsState)
  const navigate = useNavigateToPage();
  const userDetails = getUserDetails();
  const isOriginalDuplicate = useRecoilValue(duplicationTypeState) === DuplicateOption.OriginAdsets;

  useEffect(() => {
    const errors = validateCampaigns(isDuplicate, isOriginalDuplicate, selectedDuplicateCampaigns, campaignsList, accountsDomains, userDefaultConfiguration);
    if (errors.length > 0) {
      setErrors(errors);        
      setDisabledSubmit(true);
    } else {
      setErrors([]);
      setDisabledSubmit(false);
    }
    const totalAccounts = accountsDomains.reduce((sum, domain) => {
      return sum + domain.accounts.length;
    }, 0);
    setTotalAdsets(totalAccounts * campaignsList.length);
  }, [campaignsList, accountsDomains, userDefaultConfiguration, selectedDuplicateCampaigns, isDuplicate, isOriginalDuplicate]);

  const onClearClick = () => {
    setTriggerState({ loading: false, triggered: false });
    setCampaignsListState((prevState) => ({ ...prevState, campaignsList: [] }));
  };

  const submitOriginDuplication = async () => {
    const formattedData = formatOriginDuplicateSubmitDataForServer(target, selectedDuplicateCampaigns, useOriginalPostID, scheduledCampaign, isPausedCampaign, customDate);
    await CampaignsServiceProvider.submitDuplicateOriginCampaigns(formattedData);
    setMessage({ open: true, status: SNACK_BAR_STATUSES.SUCCESS, message: 'Campaigns Duplicated Successfully' });
  }

  const submitNewTargetingDupliction = async () => {
    setTriggerState({ triggered: true, loading: true });
    const formattedData = formatDuplicateSubmitDataForServer(campaignsList, selectedDuplicateCampaigns, useOriginalPostID, scheduledCampaign, isPausedCampaign, customDate);
    await CampaignsServiceProvider.submitDuplicateCampaignsDraft(formattedData);
    setMessage({ open: true, status: SNACK_BAR_STATUSES.SUCCESS, message: 'Campaigns Duplicated Successfully' });
    setTriggerState({ loading: false, triggered: false });
  }

  const submitDuplicateCampaign = async () => {
    if (isOriginalDuplicate) {
      await submitOriginDuplication();
    } else {
      await submitNewTargetingDupliction();
    }
    if (userDetails.permissions.includes('dev')) {
      alert('Dev Debug');
    }
    const redirect = `pages/facebook/fb-adset`;
    setTimeout(() => navigate(redirect), 2000);
  }

  const onSubmit = async () => {
    try {
      if (isDuplicate) {
        await submitDuplicateCampaign();
      }
      else {
        setTriggerState({ triggered: true, loading: true });
        const formattedData = formatSubmitDataForServer(campaignsList, groupName, data.contents?.article_id, accountsDomains, scheduledCampaign, isPausedCampaign, customDate);
        await CampaignsServiceProvider.submitCampaignsDraft(formattedData);
        if (userDetails.permissions.includes('dev')) {
          alert('Dev Debug');
        }
        const redirect = `pages/campaignsCreator/group-add-creatives/${groupName}/facebook${isDynamicCreative ? '/dynamic' : ''}`;
        navigate(redirect);
      }
    } catch (error) {
      const errorMessage = 'Error submitting campaigns drafts';
      setMessage({ open: true, status: SNACK_BAR_STATUSES.ERROR, message: errorMessage });
      setTriggerState({ triggered: true, loading: false });
    }
  };


  return (
    <div css={styles.container}>
      <div css={styles.buttonContainer}>
        {!isDuplicate && <Text type={Text.TEXT_TYPES.PARAGRAPH_M} color={'#7F7F7F'} style={styles.total}>{`Max Total Adsets: ${totalAdsets}`}</Text>}
        <Button type={ButtonType.Secondary} variables={variables.button} onClick={onClearClick}>Clear</Button>
        <CssTooltip
          content={errors.length > 0 ? <TooltipContent messages={errors} /> : ''}
          style={styles.tooltip as any}
          position={TOOLTIP_POSITIONS.BOTTOM_LEFT}
          variables={{
            tooltipMultilineWidth: 'fit-content',
          }}
        >
          <Button type={ButtonType.Primary} variables={variables.button} disabled={disabledSubmit} onClick={onSubmit}>Submit</Button>
        </CssTooltip>
      </div>
      <CampaignsListTable />
    </div>
  );
}
