import { CampaignListItem } from '../../createCampaignState/createCampaign.selector';
import { Configuration } from '../../../../../../configurations/configurations.types';


export const validateCampaigns = (isDuplicate: boolean = false, isOriginDuplicate: boolean = false, duplicateCampaigns: string[] = [], campaignsList: CampaignListItem[], accountsDomainList: { domain: string, accounts: string[] }[], userDefaultConfiguration: Configuration) => {
  let errors: string[] = [];
  const checksumMap = new Map();
  if (campaignsList.length === 0 && !isOriginDuplicate) {
    errors.push('Please add at least 1 campaign');
    return errors;
  }

  !isDuplicate && errors.push(...validateAccountsDomains(accountsDomainList));
  isDuplicate && duplicateCampaigns.length === 0 && errors.push('Please select at least 1 campaign to duplicate');

  campaignsList.forEach((campaign, index) => {
    const campaignErrors = [];
    if (campaign?.freeTextName && !validateFreeTextName(campaign.freeTextName)) {
      campaignErrors.push('Campaign name should be less than 10 characters and should not contain special characters');
    }
    if (campaign?.defaultBid && !validateDefaultBid(campaign.defaultBid, campaign?.maxBid || userDefaultConfiguration.maxBid)) {
      campaignErrors.push(`Default bid should be greater than 0.01 and less than ${campaign?.maxBid || userDefaultConfiguration.maxBid}`);
    }
    if (campaign?.defaultDailyBudget && !validateDefaultDailyBudget(campaign.defaultDailyBudget)) {
      campaignErrors.push('Daily budget should be less than 300');
    }
    if (campaignErrors.length > 0) {
      errors.push(`Campaign ${index + 1}: ${campaignErrors.join(', ')}`);
    }

    const { checksum } = campaign;
    if (checksumMap.has(checksum)) {
      checksumMap.get(checksum).push(index);
    } else {
      checksumMap.set(checksum, [index]);
    }
  });

  const duplicateGroups: number[][] = Array.from(checksumMap.values()).filter(
    (indexes) => indexes.length > 1
  );

  if (duplicateGroups.length > 0) {
    duplicateGroups.forEach((group) => {
      errors.push(`Duplicate Campaigns: ${group.map((index) => index + 1).join(', ')}`);
    });
  }
  return errors;
};

const validateAccountsDomains = (accountsDomainList: { domain: string, accounts: string[] }[]) => {
  let errors: string[] = [];

  const allDomainsAreEmpty = accountsDomainList.every((domainAccount) => domainAccount.domain.length === 0);

  if (accountsDomainList.length === 0 || allDomainsAreEmpty) {
    return ['Please select At least 1 domain'];
  }

  accountsDomainList.forEach((domainAccount) => {
    if (domainAccount.accounts.length === 0 && domainAccount.domain) {
      errors.push(`Please select At least 1 account for ${domainAccount.domain}`);
    }
  });

  const duplicateDomains = accountsDomainList.some((item, index, arr) =>
    arr.filter((otherItem) => otherItem.domain === item.domain).length > 1
  );

  if (duplicateDomains) {
    errors.push('Duplicate Domains Selected');
  }

  return errors;
};

export const validateFreeTextName = (name: string) => {
  const regex = /^[a-zA-Z\d]+$/g;
  const found = name.match(regex);
  return !(found == null || name.length > 10);
};

export const validateDefaultDailyBudget = (defaultDailyBudget: number) => {
  return defaultDailyBudget < 300;
};

export const validateDefaultBid = (defaultBid: number, maxBid: number) => {
  return defaultBid > 0.01 && defaultBid <= maxBid;
};
