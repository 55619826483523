import { Input, InputType } from "@ftbpro/mm-admin-ui-components"
import { useRecoilState } from "recoil";
import { targetState } from "../../../../targetState/target.atom";
import { SyntheticEvent } from "react";

export const ConversionShift = () => {
  const [target, setTarget] = useRecoilState(targetState);


  const onInputChanged = (e: SyntheticEvent<HTMLElement, Event>, value?: string) => {
    setTarget((prevState) => ({
      ...prevState,
      conversionShift: Number(value),
      conversionsEvent: []
    }
    ))
  }

  return (
    <div>
      <Input
        type={InputType.Number}
        onChange={onInputChanged}
        value={target.conversionShift?.toString() || '0'}
        variables={{
          width: '60px',
          height: '50px'
        }}
      />
    </div>
  )
}
