import { Checkbox, FontWeight, Text } from "@ftbpro/mm-admin-ui-components"
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { Card } from "../../../Card/Card"
import { useRecoilState, useRecoilValue } from 'recoil';
import { targetState } from "../../targetState/target.atom"
import { isDuplicateState } from "../../../DuplicationTarget/components/DuplicationType/DuplicationType.state";


export const TargetLanguage = () => {
  const [target, setTarget] = useRecoilState(targetState);
  const isDuplicate = useRecoilValue(isDuplicateState);
  const isLanguageSet = target.isLanguageSet;
  const { language } = useParams();


  useEffect(() => {
    if (language) {
      setTarget((prevTarget) => ({
        ...prevTarget,
        language: language,
      }));
    }
  }, []);

  const onCheckChanged = () => {
    setTarget((prevTarget) => ({
      ...prevTarget,
      isLanguageSet: !isLanguageSet,
    }));
  }

  return (
    <>
      <Card>
        {
          !isDuplicate &&
          <Text type={Text.TEXT_TYPES.PARAGRAPH_L} fontWeight={FontWeight.Medium}>{`Language: ${language}`}</Text>
        }
        <Checkbox
          checked={isLanguageSet}
          onChange={onCheckChanged}
        >
          <Text>{`Target Language`}</Text>
        </Checkbox>
      </Card>
    </>

  )
}
