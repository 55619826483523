/** @jsxImportSource @emotion/react */
import { Button, CssTooltip, RegularTabs, SNACK_BAR_STATUSES, TabData, TextButton, TOOLTIP_POSITIONS } from '@ftbpro/mm-admin-ui-components';
import { Settings } from "./components/Settings/Settings";
import { Target } from "./components/Target/Target";
import { css } from "@emotion/react";
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable, useResetRecoilState, useSetRecoilState } from 'recoil';
import { validateCampaign } from "./settingsAndTarget.validations";
import { settingsState } from "./components/Settings/settingsState/settings.atom";
import { clearToggle, targetState } from "./components/Target/targetState/target.atom";
import { campaignData } from "./components/Target/targetState/target.selector";
import { formatCampaignsDraftsForClient, formatCampaignToServer } from './settingsAndTarget.formatter';
import { campaignsDraftTriggeredState, campaignsTableState } from '../../createCampaignState/createCampaign.selector';
import { CampaignsServiceProvider } from '../../../../../../../services/campaigns/CampaignsServiceProvider';
import { campaignMessageState } from '../CreateCampaignMessage/createCampaignMessage.atom';
import { TooltipContent } from '../../../../../../../components/TooltipContent/TooltipContent';
import { searchState } from './components/Target/components/SearchCampaignContainer/searchCampaign.atom';
import { DuplicationTarget } from './components/DuplicationTarget/DuplicationTarget';
import { duplicationTypeState, isDuplicateState } from './components/DuplicationTarget/components/DuplicationType/DuplicationType.state';
import { DuplicateOption } from '../../createCampign.typs';

const getStyles =  (contentFullHeight: boolean) => ({
  container: css({
    backgroundColor: 'white',
    position: 'relative',
    overflowY: 'auto',
    zIndex: 2,
    width: '352px',
  }),
  content: css({
    overflowY: 'auto',
    height:  contentFullHeight ?  'calc(100vh - 100px)' : 'calc(100vh - 210px)',
    position: 'relative',
  }),
  applyButton: css({
    position: 'fixed',
    bottom: '0',
    zIndex: 3,
    backgroundColor: 'white',
    padding: '16px 26px',
    display: 'flex',
    justifyContent: 'center',
    background: 'white', 
    height: '180',
    flexDirection: 'column' ,
    alignItems: 'center'  ,
    gap: '10px' 
  }),
});

const TABS_VALUES = [
  {
    label: 'Target',
    key: 'target',
  },
  {
    label: 'Settings',
    key: 'settings',
  },
]

export const SettingsAndTarget = () => {
  const setTriggerState = useSetRecoilState(campaignsDraftTriggeredState);
  const [tab, setTab] = useState('target');
  const [campaignsList, setCampaignsList] = useRecoilState(campaignsTableState);
  const campaignTarget = useRecoilValue(targetState);
  const campaignSettings = useRecoilValue(settingsState);
  const { isSearchCampaign } = useRecoilValue(searchState);
  const data = useRecoilValueLoadable(campaignData);
  const [disabledApply, setDisabledApply] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const setMessage = useSetRecoilState(campaignMessageState);
  const isDuplicate = useRecoilValue(isDuplicateState);
  const isOriginDuplicate = useRecoilValue(duplicationTypeState) === DuplicateOption.OriginAdsets;
  const resetTarget = useResetRecoilState(targetState);
  const resetSettings = useResetRecoilState(settingsState);
  const setClearToggles = useSetRecoilState(clearToggle);

  useEffect(() => {
    if (data.state === 'hasError') {
      setMessage({ open: true, message: 'Error fetching campaign data' });
    }
  }, [data.state]);

  const onTabSelected = (tabData: TabData) => {
    setTab(tabData.key as string);
  }

  const onApplyDraft = async () => {
    try {
      setTriggerState({ triggered: true, loading: true });
      const formattedDataToServer = formatCampaignToServer(campaignTarget, isSearchCampaign);
      const response = await CampaignsServiceProvider.generateCampaignsDraft(formattedDataToServer);
      const drafts = response.drafts;
      const formattedCampaignsDrafts = formatCampaignsDraftsForClient(drafts, campaignsList.campaignsList);
      setCampaignsList({ campaignsList: formattedCampaignsDrafts });
      setTriggerState({ triggered: true, loading: false });
    } catch (error) {
      const errorMessage = 'Error generating campaigns drafts';
      setMessage({ open: true, status: SNACK_BAR_STATUSES.ERROR, message: errorMessage });
      setTriggerState({ triggered: true, loading: false });
    }
  }

  useEffect(() => {
    const errors = validateCampaign(campaignTarget, isSearchCampaign);
    if (errors.length > 0) {
      setErrors(errors);
      setDisabledApply(true);
    } else {
      setErrors([]);
      setDisabledApply(false);
    }
  }, [campaignTarget, campaignSettings, isSearchCampaign]);

  const onDiscardAllClick = () => {
     resetTarget();
     resetSettings();
     setClearToggles(true);
  }

  const styles = getStyles(isOriginDuplicate ? true : false);

  return (
    <div css={styles.container} >
      <RegularTabs
        tabs={TABS_VALUES}
        onTabSelected={onTabSelected}
      />
      <div css={styles.content}>
        {tab === 'target' && (isDuplicate ? < DuplicationTarget /> : <Target />)}
        {tab === 'settings' && <Settings />}
      </div>
      {
        !isOriginDuplicate &&
        <div css={styles.applyButton}>
          <CssTooltip
            content={errors.length > 0 ? <TooltipContent messages={errors} /> : ''}
            position={TOOLTIP_POSITIONS.RIGHT}
            variables={{
              tooltipMultilineWidth: 'fit-content',
            }}
          >
            <Button
              content="Apply Draft"
              onClick={onApplyDraft}
              variables={{
                width: '300px',
                height: '32px',
              }}
              disabled={disabledApply}
            />
          </CssTooltip>
          <TextButton content='Discard All Changes' onClick={onDiscardAllClick}/>
        </div>
      }
    </div>
  );
}

