/** @jsxImportSource @emotion/react */
import { RadioGroup, Text, Toggle } from '@ftbpro/mm-admin-ui-components';
import { Card } from '../../../Card/Card';
import { useRecoilState, useRecoilValue } from 'recoil';
import { clearToggle, targetState } from '../../targetState/target.atom';
import { GenderType } from '../../../../../../createCampign.typs';
import { useEffect, useState } from 'react';
import { css } from '@emotion/react';

const genderValues = [
  { label: 'All', value: GenderType.All },
  { label: 'Men', value: GenderType.Men},
  { label: 'Women', value: GenderType.Women},
]

const styles = {
  toggleWrapper: css({
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
  })
};

export const Gender = () => {
  const [ target, setTarget] = useRecoilState(targetState);
  const [showGender, setShowGender] = useState(!!target.gender);
  const closeToggle = useRecoilValue(clearToggle)

  useEffect(() => {
    if(closeToggle){
      setShowGender(false);
    }
  },[closeToggle])

  const onToggleGender = () => {
    if (showGender) {
      setTarget((prevTarget) => ({
          ...prevTarget,
          gender: null
        }
      ));
    } else {
      setTarget((prevTarget) => ({
          ...prevTarget,
          gender: GenderType.All
        }
      ));
    }
    setShowGender(!showGender);
  };

  const onCheckChanged = (value: string) => {
    setTarget((prevTarget) => ({
      ...prevTarget,
      gender: value as GenderType,
    }));
  }

  return (
    <Card>
      <div css={styles.toggleWrapper}>
        <Toggle
          checked={showGender}
          onChange={onToggleGender}
        />
        <Text
          type={Text.TEXT_TYPES.PARAGRAPH_M}
        >
          Gender
        </Text>
      </div>
      {showGender && <RadioGroup
        items={genderValues}
        orientation={RadioGroup.ORIENTATION.HORIZONTAL}
        onCheckChanged={onCheckChanged}
        checkedValue={target.gender || GenderType.All}
      />}
    </Card>
  );
}
