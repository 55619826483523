import { atom } from 'recoil';
import { SearchProvider } from '../../../../../../createCampign.typs';


type DuplicateSettingsState = {
  selectedDuplicateCampaigns: any,
  useOriginalPostID: boolean,
  searchProvider?: SearchProvider,
  isSearch: boolean
}

export const duplicateSettingsState = atom <DuplicateSettingsState>({
   key: 'duplicateSettingsState',
   default: { selectedDuplicateCampaigns: [], useOriginalPostID: true, isSearch: false}
})

