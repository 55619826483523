import { Dropdown, DropdownListItemProps, FontWeight, Text } from "@ftbpro/mm-admin-ui-components"
import { useRecoilState, useRecoilValue } from "recoil";
import { targetState } from "../../../../targetState/target.atom";
import { useEffect } from "react";
import { BidStrategy } from "../../../../../../../../createCampign.typs";
import { ClickEventType } from "@ftbpro/mm-admin-ui-components/src/types";
import { searchState } from "../../../SearchCampaignContainer/searchCampaign.atom";
import { UNSELECT } from "../../../../../../../../createCampaign.strings";
import { Card } from "../../../../../Card/Card";

const bidStrategyItems = [
  { content: 'Lowest Cost', value: BidStrategy.LowestCost },
  { content: 'Bid Cap', value: BidStrategy.BidCap },
  { content: 'Cost Cap', value: BidStrategy.CostCap },
]

interface CampaignBidStrategyProps {
  multiSelection?: boolean;
  showDefaultValue?: boolean;
  cardWrapper?: boolean;
}
export const CampaignBidStrategy = ({ multiSelection = true, showDefaultValue = true, cardWrapper = false }: CampaignBidStrategyProps) => {
  const [target, setTarget] = useRecoilState(targetState);
  const { bidStrategy } = target;
  const { isSearchCampaign } = useRecoilValue(searchState);

  useEffect(() => {
    if (!bidStrategy && showDefaultValue) {
      setTarget((prevTarget) => ({
        ...prevTarget,
        bidStrategy: isSearchCampaign ? [BidStrategy.CostCap] : [BidStrategy.BidCap],
      }));
    }
  }, [isSearchCampaign, showDefaultValue, bidStrategy]);

  const onSelectedChange = (e: ClickEventType<HTMLElement>, selectedItem: DropdownListItemProps) => {
    multiSelection ?
      setTarget((prevTarget) => {
        const copyBidStrategy = prevTarget.bidStrategy ? [...prevTarget.bidStrategy] : [];
        return {
          ...prevTarget,
          bidStrategy: [...copyBidStrategy, selectedItem.value as BidStrategy]
        }
      })
      :
      setTarget((prevTarget) => {
        return {
          ...prevTarget,
          bidStrategy: [selectedItem.value as BidStrategy]
        }
      })
  }

  const onBidStrategyDelete = (e: ClickEventType<HTMLElement>, selectedItem: DropdownListItemProps) => {
    setTarget((prevTarget) => {
      return {
        ...prevTarget,
        bidStrategy: prevTarget.bidStrategy?.filter((bidStrategy) => bidStrategy !== selectedItem.value)
      }
    });
  }

  const onBidStrategySelectAll = (e: ClickEventType<HTMLElement>, selectedItems: DropdownListItemProps[]) => {
    setTarget((prevTarget) => {
      return {
        ...prevTarget,
        bidStrategy: selectedItems.map((item) => item.value as BidStrategy)
      }
    });
  }

  const getBidStrategyItems = () => {
    if (multiSelection) {
      return bidStrategyItems
    }
    return [{ value: UNSELECT, content: UNSELECT }, ...bidStrategyItems]
  }

  const renderBidStrategyDropdown = () => {
    return (
      <Dropdown
        items={getBidStrategyItems()}
        selectAllLabel={'Select All'}
        multiSelection={multiSelection}
        showCheckboxes
        placeholder="Bid Strategy"
        selectedValues={target.bidStrategy as string[]}
        selectedValue={target.bidStrategy && target.bidStrategy[0] as string}
        onSelectedChange={onSelectedChange}
        onValueRemove={onBidStrategyDelete}
        onSelectAllChange={onBidStrategySelectAll}
      />
    )
  }

  return (
    cardWrapper ?
      <Card>
        <Text type={Text.TEXT_TYPES.PARAGRAPH_L} fontWeight={FontWeight.Medium}>Bid Strategy</Text>
        {renderBidStrategyDropdown()}
      </Card>
      :
      renderBidStrategyDropdown()
  )
}
