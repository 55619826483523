/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FontWeight, Loader, Text } from '@ftbpro/mm-admin-ui-components';
import { SettingsAndTarget } from './components/SettingsAndTarget/SettingsAndTarget';
import { CampaignsListContainer } from './components/CampaignsListContainer/CampaignsListContainer';
import { CreateCampaignMessage } from './components/CreateCampaignMessage/CreateCampaignMessage';
import { useLocation, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { userConfiguration, userDataState } from './createCampaignState/user.selector';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { ErrorPage } from '../../../../../components/ErrorPage/ErrorPage';
import { isDuplicateState } from './components/SettingsAndTarget/components/DuplicationTarget/components/DuplicationType/DuplicationType.state';
import { duplicateSettingsState } from './components/SettingsAndTarget/components/Settings/components/DuplicateCampaignsSettings/duplicateSettings.state';
import { rootState } from '../../../../../InitializeRecoilState';
import { CampaignType, SearchProvider } from './createCampign.typs';
import { useNavigateToPage } from '../../../../../hooks/useNavigate';

const styles = {
  container: css({
    marginLeft: "3px",
    position: "relative"
  }),
  title: css({
    FontWeight: "700",
    padding: "20px",
    backgroundColor: "#ffffff",
    marginBottom: "3px",
  }),
  contentContainer: css({
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
  }),
  errorContainer: css({
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 2000,
  }),
}

export const CreateCampaignDashboard = () => {
  const { article_name } = useParams();
  const [isDuplicate, setIsDuplicate] = useRecoilState(isDuplicateState);
  const userConfigurationLoadable = useRecoilValueLoadable(userConfiguration);
  const { userDomains, userAccounts } = useRecoilValue(userDataState);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();
  const setDuplicateSettings = useSetRecoilState(duplicateSettingsState);
  const { adsetsDuplication } = useRecoilValue(rootState);
  const navigate = useNavigateToPage();

  useEffect(() => {
    switch (userConfigurationLoadable.state) {
      case 'loading':
        setLoading(true);
        break;
      case 'hasError':
        setLoading(false);
        setError(userConfigurationLoadable.contents.message || 'An error occurred fetching the data');
        break;
      case 'hasValue':
        setLoading(false);
        const domainsAccountError = userDomains.facebook.length === 0 || userAccounts.facebook.length === 0 ? 'No connection to a “domain” or “account” group.' : '';
        const preferencesError = userConfigurationLoadable.contents ? '' : 'Missing campaign preferences.';
        const error = domainsAccountError || preferencesError ? `${domainsAccountError} ${preferencesError}` : null;
        setError(error);
        setIsDuplicate(location.pathname.includes('/duplicate'));
        break;
      default:
        break;
    }
  }, [userConfigurationLoadable.state, userConfigurationLoadable.contents, userAccounts, userDomains]);

  useEffect(() => {
    if (isDuplicate) {
      setDuplicateSettingsState();
    }
  }, [isDuplicate])

  const setDuplicateSettingsState = () => {
    if (!adsetsDuplication?.adsets) {
      setError('Missing adsets. Unable to create a duplicate campaign');
      const redirect = `pages/facebook/fb-adset`;
      setTimeout(() => navigate(redirect), 3000);
    }else{
      const selectedDuplicateCampaigns = adsetsDuplication?.adsets?.map((campaign: { name: string }) => campaign.name) || [];
      const searchProvider = adsetsDuplication?.provider as SearchProvider;
      const isSearch = adsetsDuplication?.type === CampaignType.Search;
      const useOriginalPostID = !isSearch;
      setDuplicateSettings({
        selectedDuplicateCampaigns,
        searchProvider,
        isSearch,
        useOriginalPostID
      })
    }
  }

  return (
    <>
      {loading ? (
        <Loader size={Loader.LOADER_SIZES.FULL_SCREEN} />
      ) : error ? (
        <ErrorPage title={'Sorry, you don’t have access'} message={'Please contact the Compliance Team for assistance.'} description={error} />
      ) : (
        <div css={styles.container}>
          <Text type={Text.TEXT_TYPES.PARAGRAPH_XXL}
            fontWeight={FontWeight.Bold}
            style={styles.title}
          >{!isDuplicate ? `Create New Campaigns in Article: ${article_name}` : 'Create Duplicate Adsets'}
          </Text>
          <div css={styles.contentContainer}>
            <SettingsAndTarget />
            <CampaignsListContainer />
          </div>
          <CreateCampaignMessage />
        </div>
      )}
    </>);
}
