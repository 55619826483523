/** @jsxImportSource @emotion/react */
import { Checkbox, Dropdown, DropdownListItemProps, Text } from '@ftbpro/mm-admin-ui-components';
import { Card } from '../../../Card/Card';
import { ClickEventType } from '@ftbpro/mm-admin-ui-components/src/types';
import { useRecoilState, useRecoilValue } from 'recoil';
import { duplicateSettingsState } from './duplicateSettings.state';
import { rootState } from '../../../../../../../../../../../InitializeRecoilState';

export const DuplicateCampaignsSettings = () => {
  const [duplicateSettings, setDuplicateSettings] = useRecoilState(duplicateSettingsState)
  const { selectedDuplicateCampaigns, useOriginalPostID, isSearch } = duplicateSettings;
  const {adsetsDuplication} = useRecoilValue(rootState);
  const duplicateCampaignsItems = adsetsDuplication?.adsets?.map((campaign: { name: string }) => campaign.name) || [];

  const onSelectedChange = (e: ClickEventType<HTMLElement>, selectedItem: DropdownListItemProps) => {
    setDuplicateSettings((prevState) => {
      const selectedCampaigns = prevState?.selectedDuplicateCampaigns ? [...prevState.selectedDuplicateCampaigns] : [];
      return {
        ...prevState,
        selectedDuplicateCampaigns: [...selectedCampaigns, selectedItem.value as string]
      }
    });
  };

  const onCampaignRemove = (e: ClickEventType<HTMLElement>, selectedItem: DropdownListItemProps) => {
    setDuplicateSettings((prevState) => ({
      ...prevState,
      selectedDuplicateCampaigns: prevState.selectedDuplicateCampaigns.filter((campaign: string) => campaign !== selectedItem.value)
    }))
  };

  const onCampaignsSelectAll = (e: ClickEventType<HTMLElement>, selectedItems: DropdownListItemProps[]) => {
    setDuplicateSettings((prevState) => ({
      ...prevState,
      selectedDuplicateCampaigns: selectedItems.map((item) => item.value as string)
    }))
  };

  const onUseOriginalPostIDChange = () => {
    setDuplicateSettings((prevState) => ({
      ...prevState,
      useOriginalPostID: !prevState.useOriginalPostID
    }))
  };

  return (
    <>
      <Card>
        <Dropdown
          items={duplicateCampaignsItems}
          showCheckboxes
          multiSelection
          search
          onSelectedChange={onSelectedChange}
          onValueRemove={onCampaignRemove}
          onSelectAllChange={onCampaignsSelectAll}
          selectedValues={selectedDuplicateCampaigns || []}
          selectAllLabel={'Select All'}
          placeholder={'Select Ad Sets'}
          expandableChips
        />
      </Card>
      {!isSearch && <Card>
        <Checkbox
          checked={useOriginalPostID}
          onChange={onUseOriginalPostIDChange}
        >
          <Text type={Text.TEXT_TYPES.PARAGRAPH_M}>Use Existing Post ID</Text>
        </Checkbox>
      </Card>}
    </>
  );
};
