/** @jsxImportSource @emotion/react */
import { Dropdown, DropdownListItemProps, Text, TextButton } from "@ftbpro/mm-admin-ui-components";
import { Card } from '../../../../../Card/Card';
import { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { AddFillIcon, DeleteIcon } from "@ftbpro/mm-admin-assets";
import { ClickEventType } from "@ftbpro/mm-admin-ui-components/src/types";
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from "recoil";
import { targetState } from '../../../../../Target/targetState/target.atom';
import { SearchProvider } from '../../../../../../../../createCampign.typs';
import { getDomainAccounts } from '../../../../settingsState/settings.selector';
import { searchState } from '../../../../../Target/components/SearchCampaignContainer/searchCampaign.atom';
import { useLocation } from "react-router-dom";

interface AccountsDomainProps {
  onDeleteAccountsDomain: (index: number) => void;
  onAddAccountsDomain?: () => void;
  onDomainSelect: (selectedItem: string, index: number) => void;
  onAddAccount: (selectedItem: string, index: number) => void;
  onRemoveAccount: (selectedItem: string, index: number) => void;
  onSelectAllAccounts: (selectedItems: string[], index: number) => void;
  selectedDomain: string;
  selectedAccounts: string[];
  index: number;
  showDeleteIcon: boolean;
  showAddIcon: boolean;
  isValidDomain: boolean;
}

const styles = {
  buttons: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  })
}
export const AccountsDomain = (props: AccountsDomainProps) => {
  const {
    onDeleteAccountsDomain,
    onAddAccountsDomain,
    onDomainSelect,
    onAddAccount,
    onRemoveAccount,
    selectedDomain,
    selectedAccounts,
    index,
    onSelectAllAccounts,
    showDeleteIcon,
    showAddIcon,
    isValidDomain
  } = props;


  const [disabledAccounts, setDisabledAccounts] = useState(selectedDomain?.length === 0);
  const domainAccountItems = useRecoilValueLoadable(getDomainAccounts);
  const [domainAccounts, setDomainAccounts] = useState<Record<string, string[]>>({});
  const [target, _] = useRecoilState(targetState);
  const { isSearchCampaign } = useRecoilValue(searchState);
  const location = useLocation();

  const getExploreAdsDomainAccounts = (domainAccountItems: Record<string, string[]>) => {
    const exploreAdsAccounts = Object.keys(domainAccountItems).filter(domain => domain.includes('EA-'));
    const exploreAdsAccountsObj = exploreAdsAccounts.reduce((acc, domain) => {
      acc[domain] = domainAccountItems[domain];
      return acc;
    }, {} as Record<string, string[]>);
    return exploreAdsAccountsObj;
  }

  const removeSearchDomainAccounts = (domainAccountItems: Record<string, string[]>) => {
    const filterSearchDomains = Object.keys(domainAccountItems).filter(domain => !domain.includes('EA-') && domain !== 'tonic');
    const searchDomainAccounts = filterSearchDomains.reduce((acc, domain) => {
      acc[domain] = domainAccountItems[domain];
      return acc;
    }, {} as Record<string, string[]>);
    return searchDomainAccounts;
  }

  const getAFSdomainAccounts = (domainAccountItems: Record<string, string[]>) => {
    const queryParams = new URLSearchParams(location.search);
    const articleDomains = queryParams.get("artDomain")?.split(',');
    if(articleDomains){
    const AFSdomains = Object.keys(domainAccountItems)?.filter(domain => articleDomains?.includes(domain));
    const AFSDomainsAcoounts = AFSdomains.reduce((acc, domain) => {
      acc[domain] = domainAccountItems[domain]
      return acc; 
    }, {} as Record<string, string[]>);
    return AFSDomainsAcoounts;
  }
  return removeSearchDomainAccounts(domainAccountItems);
  };

  useEffect(() => {
    if (domainAccountItems.state === 'hasValue') {
        const searchProvider = target.searchCampaign?.searchProvider;
        setDomainsAccountsBySearchProvider(searchProvider);
    }
  }, [domainAccountItems.state, isSearchCampaign, target.searchCampaign?.searchProvider ]);

  const setDomainsAccountsBySearchProvider = (searchProvider?: SearchProvider) => {
    switch (searchProvider) {
      case SearchProvider.Tonic:
        if (domainAccountItems.contents['tonic']) {
          const tonicAccounts = domainAccountItems.contents['tonic'];
          setDomainAccounts({ tonic: tonicAccounts });
        }
        break;
      case SearchProvider.ExploreAds:
        const exploreAdsAccounts = getExploreAdsDomainAccounts(domainAccountItems.contents);
        setDomainAccounts(exploreAdsAccounts);
        break;
      case SearchProvider.AFS: 
       const AFSaccounts = getAFSdomainAccounts(domainAccountItems.contents);
       setDomainAccounts(AFSaccounts);
       break;
      default:
        const filteredSearchDomains = removeSearchDomainAccounts(domainAccountItems.contents);
        setDomainAccounts(filteredSearchDomains);
    }
  }

  const onDomainChange = (e: ClickEventType<HTMLElement>, selectedItem: DropdownListItemProps) => {
    onDomainSelect(selectedItem.value as string, index);
    if (disabledAccounts) {
      setDisabledAccounts(false);
    }
  }

  const onAccountsChange = (e: ClickEventType<HTMLElement>, selectedItem: DropdownListItemProps) => {
    onAddAccount(selectedItem.value as string, index);
  }

  const onAccountRemove = (e: ClickEventType<HTMLElement>, selectedItem: DropdownListItemProps) => {
    onRemoveAccount(selectedItem.value as string, index);
  }


  const onSelectAllChange = (e: ClickEventType<HTMLElement>, selectedItems: DropdownListItemProps[]) => {
    const accounts = selectedItems.map(selectedItem => selectedItem.value) as string[];
    onSelectAllAccounts(accounts, index);
  }

  const showAddAccountsDomainIcon = showAddIcon && index < Object.keys(domainAccounts)?.length - 1;


  return (
    <Card>
      <Text>Domain</Text>
      <Dropdown
        items={domainAccounts ? Object.keys(domainAccounts) : []}
        search
        onSelectedChange={onDomainChange}
        selectedValue={selectedDomain}
        error={isValidDomain ? '' : 'Domain already exists'}
        loading={domainAccountItems.state === 'loading'}
      />

      <Text>Accounts</Text>
      <Dropdown
        items={domainAccounts ? domainAccounts[selectedDomain] : []}
        showCheckboxes
        multiSelection
        expandableChips
        search
        disabled={disabledAccounts || !isValidDomain}
        onSelectedChange={onAccountsChange}
        onValueRemove={onAccountRemove}
        onSelectAllChange={onSelectAllChange}
        selectedValues={selectedAccounts}
        selectAllLabel={'Select All'}
      />

      <div css={styles.buttons}>
        {showDeleteIcon && <TextButton content={'Delete'} onClick={() => onDeleteAccountsDomain(index)} icon={DeleteIcon} />}
        {showAddAccountsDomainIcon && <TextButton content={'Add Domain'} onClick={onAddAccountsDomain} icon={AddFillIcon} />}
      </div>
    </Card>
  );
}
