/** @jsxImportSource @emotion/react */
import { Checkbox, FontWeight, RadioGroup, Text } from "@ftbpro/mm-admin-ui-components";
import { Card } from "../../../Card/Card";
import { useRecoilState, useRecoilValue } from "recoil";
import { targetState } from "../../targetState/target.atom";
import { searchState } from "../SearchCampaignContainer/searchCampaign.atom";

export const PLATFORM_OPTIONS = [
  {
    label: 'Facebook',
    value: 'facebook',
  },
];

export const Platform = () => {

  const [target, setTarget] = useRecoilState(targetState);
  const { uploadToInstagram } = target;
  const { isSearchCampaign } = useRecoilValue(searchState);



  const onUploadToInstagramChange = () => {
    setTarget((prevTarget) => ({
      ...prevTarget,
      uploadToInstagram: !uploadToInstagram
    }));
  }

  return (
    <Card>
      <Text
        type={Text.TEXT_TYPES.PARAGRAPH_L}
        fontWeight={FontWeight.Medium}
      >
        Platform
      </Text>
      <RadioGroup
        items={PLATFORM_OPTIONS}
        defaultCheckedValue={'facebook'}
        disabled
      />
      {!isSearchCampaign &&
        <Checkbox
          checked={uploadToInstagram}
          onChange={onUploadToInstagramChange}
        >
          <Text type={Text.TEXT_TYPES.PARAGRAPH_M}>Upload to Instagram</Text>
        </Checkbox>
      }
    </Card>
  );
}
