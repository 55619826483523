import { useRecoilValue } from 'recoil';
import { ScheduledCampaign } from './components/ScheduledCampaign/ScheduledCampaign';
import { AccountsDomains } from './components/AccountsDomains/AccountsDomains';
import { PausedCampaign } from './components/PausedCampaign/PausedCampaign';
import { DuplicateCampaignsSettings } from './components/DuplicateCampaignsSettings/DuplicateCampaignsSettings';
import { isDuplicateState } from '../DuplicationTarget/components/DuplicationType/DuplicationType.state';


export const Settings = () => {
  const isDuplicate = useRecoilValue(isDuplicateState);

  return (
    <>
      <PausedCampaign/>
      <ScheduledCampaign/>
      {isDuplicate
        ? <DuplicateCampaignsSettings />
        : <AccountsDomains/>
      }
    </>
  )
}
