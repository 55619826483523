import { atom } from "recoil";
import { DuplicateOption } from "../../../../../../createCampign.typs";

export const isDuplicateState = atom({
  key: 'isDuplicateState',
  default: false,
});

export const duplicationTypeState = atom<DuplicateOption>({
  key: 'duplicationTypeState',
  default: DuplicateOption.NewTargeting
})
