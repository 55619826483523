import { HTTP_METHODS, mmNetworkService } from '../../core/network/mmServicesApiProvider';
import { CampaignListItem } from '../../pages/createCampaign/CreateCampaignDashboard/compnents/CreateCampaignDashboard/createCampaignState/createCampaign.selector';
import { BidStrategy, TemplatePayload } from '../../pages/createCampaign/CreateCampaignDashboard/compnents/CreateCampaignDashboard/createCampign.typs';

const REACT_APP_BACKOFFICE_URL = process.env.REACT_APP_BACKOFFICE_URL;
const PM_CAMPAIGNS_MANAGEMENT_SERVICE_BASE_URL = process.env.REACT_APP_PM_CAMPAIGNS_MANAGEMENT_SERVICE_BASE_URL;
const DRAFT = 'draft';

type submitCampaignsDraftPayload = {
  drafts: CampaignListItem[],
  articleGroup: string,
  domainsAndAccounts: Record<string, string[]>
  startOn: Date | null,
  pausedCampaign: boolean,
};

type submitDuplicateCampaignsDraftPayload = {
  drafts: CampaignListItem[],
  originalCampaigns: string[],
  startOn: Date | null,
  pausedCampaign: boolean,
  useOriginalPostId: boolean,
};


type submitDuplicateOriginCampaigns = {
  bidStrategy: null | BidStrategy,
  bid : null | number,
  conversionEvent: null | string,
  country: null | string
  originalCampaigns: string[],
  startOn: Date | null,
  pausedCampaign: boolean,
  useOriginalPostId: boolean,
}

export class CampaignsServiceProvider {

  static getTonicCampaign() {
    const url = `${REACT_APP_BACKOFFICE_URL}/campaigns/tonic`;
    const configs = {
      method: HTTP_METHODS.GET,
    };

    return mmNetworkService.fetch(url, configs);
  }

  static getCampaignData() {
    const pathname = window.location.pathname;
    const pathnameParts = pathname.split('/');
    const articleGroup = pathnameParts[2];
    const articleName = pathnameParts[3];
    const url = `${REACT_APP_BACKOFFICE_URL}/campaign/article-data/${articleGroup}/${articleName}`;
    const configs = {
      method: HTTP_METHODS.GET,
    };

    return mmNetworkService.fetch(url, configs);
  }

  static getFbInterestSearch(search: string) {
    const url = `${REACT_APP_BACKOFFICE_URL}/fb-interest-search/${search}`;
    const configs = {
      method: HTTP_METHODS.GET,
    };

    return mmNetworkService.fetch(url, configs);
  }

  static getFbInterestSuggestions(interests: { terms: string[] }) {
    const url = `${REACT_APP_BACKOFFICE_URL}/fb-interest-suggestions`;
    const configs = {
      method: HTTP_METHODS.POST,
      body: JSON.stringify(interests)
    };

    return mmNetworkService.fetch(url, configs);
  }

  static getDomainAccounts() {
    const url = `${PM_CAMPAIGNS_MANAGEMENT_SERVICE_BASE_URL}/facebook/domain-accounts`;
    const configs = {
      method: HTTP_METHODS.GET,
    };

    return mmNetworkService.fetch(url, configs);
  }

  static generateCampaignsDraft(campaignData: any) {
    const url = `${PM_CAMPAIGNS_MANAGEMENT_SERVICE_BASE_URL}/${DRAFT}/generate`;

    const configs = {
      method: HTTP_METHODS.POST,
      body: JSON.stringify(campaignData),
    };
    return mmNetworkService.fetch(url, configs);
  }

  static getTemplates() {
    const url = `${PM_CAMPAIGNS_MANAGEMENT_SERVICE_BASE_URL}/templates?onlyDetails=true`

    const configs = {
      method: HTTP_METHODS.GET,
    };
    return mmNetworkService.fetch(url, configs);
  }

  static getTemplateById(templateId: string) {
    const url = `${PM_CAMPAIGNS_MANAGEMENT_SERVICE_BASE_URL}/templates/${templateId}`;

    const configs = {
      method: HTTP_METHODS.GET,
    };
    return mmNetworkService.fetch(url, configs);
  }

  static getTemlateUsers = (templateId: string) => {
    const url = `${PM_CAMPAIGNS_MANAGEMENT_SERVICE_BASE_URL}/templates/${templateId}/users`;

    const configs = {
      method: HTTP_METHODS.GET,
    };
    return mmNetworkService.fetch(url, configs);
  }

  static createTemplate(templateData: TemplatePayload) {
    const url = `${PM_CAMPAIGNS_MANAGEMENT_SERVICE_BASE_URL}/templates`;
    const configs = {
      method: HTTP_METHODS.POST,
      body: JSON.stringify(templateData),
    }
    return mmNetworkService.fetch(url, configs);
  }

  static updateTemplate(templateId: string, templateData: TemplatePayload) {
    const url = `${PM_CAMPAIGNS_MANAGEMENT_SERVICE_BASE_URL}/templates/${templateId}`;

    const configs = {
      method: HTTP_METHODS.PUT,
      body: JSON.stringify(templateData),
    };
    return mmNetworkService.fetch(url, configs);
  }

  static updateTemplateUsers(templateId: string, userIds: string[]){
    const url = `${PM_CAMPAIGNS_MANAGEMENT_SERVICE_BASE_URL}/templates/${templateId}/users`;
    const configs = {
      method: HTTP_METHODS.PUT,
      body: JSON.stringify({userIds}),
    };
    return mmNetworkService.fetch(url, configs);
  }

  static deleteTemplate(templateId: string) {
    const url = `${PM_CAMPAIGNS_MANAGEMENT_SERVICE_BASE_URL}/templates/${templateId}`;

    const configs = {
      method: HTTP_METHODS.DELETE,
    };
    return mmNetworkService.fetch(url, configs);
  }

  static submitCampaignsDraft(payload: submitCampaignsDraftPayload) {
    const url = `${PM_CAMPAIGNS_MANAGEMENT_SERVICE_BASE_URL}/${DRAFT}/submit`;

    const configs = {
      method: HTTP_METHODS.POST,
      body: JSON.stringify(payload),
    };
    return mmNetworkService.fetch(url, configs);
  }

  static submitDuplicateCampaignsDraft(payload: submitDuplicateCampaignsDraftPayload) {
    const url = `${PM_CAMPAIGNS_MANAGEMENT_SERVICE_BASE_URL}/${DRAFT}/submit/duplicate`;

    const configs = {
      method: HTTP_METHODS.POST,
      body: JSON.stringify(payload),
    };
    return mmNetworkService.fetch(url, configs);
  }

  static submitDuplicateOriginCampaigns (payload: submitDuplicateOriginCampaigns) {
    const url = `${PM_CAMPAIGNS_MANAGEMENT_SERVICE_BASE_URL}/${DRAFT}/submit/duplicateCampaigns`;

    const configs = {
      method: HTTP_METHODS.POST,
      body: JSON.stringify(payload),
    };
    return mmNetworkService.fetch(url, configs);

  }
}


