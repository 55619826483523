/** @jsxImportSource @emotion/react */
import { Dropdown, DropdownListItemProps, FontWeight, Text } from "@ftbpro/mm-admin-ui-components";
import { Card } from "../../../Card/Card";
import { css } from "@emotion/react";
import { useRecoilState, useRecoilValueLoadable, useRecoilValue } from 'recoil';
import { campaignData } from "../../targetState/target.selector";
import { targetState } from "../../targetState/target.atom";
import { ClickEventType } from "@ftbpro/mm-admin-ui-components/src/types";
import { useEffect, useState } from "react";
import { UNSELECT } from "../../../../../../createCampaign.strings";


const styles = {
  card: css({
    gap: '8px'
  })
}

interface TargetCountriesProps {
  multiSelection?: boolean;
  required?: boolean
}
export const TargetCountries = ({ multiSelection = true, required = true }: TargetCountriesProps) => {
  const [target, setTarget] = useRecoilState(targetState);
  const [countriesOptions, setCountriesOptions] = useState<{ id: string, value: string }[]>([]);
  const { language, isLanguageSet, countries } = target;
  const data = useRecoilValueLoadable(campaignData);

  useEffect(() => {
    getCountries();
  }, [data.state, isLanguageSet, language]);


  const filterCountriesByLanguage = (countries: Record<string, string>, countriesLanguages: Record<string, string>) => {
    return Object.keys(countries)
      .filter((country) => countriesLanguages[country] === language)
      .map((country) => ({ id: country, value: countries[country] }));
  }

  const getCountries = () => {
    if (data.state === 'hasValue') {
      const countries = data.contents.countries.facebook;
      const countriesLanguages = data.contents.countries_languages;
      let countriesOptionsList: { id: string; value: string }[] = [];
      if (isLanguageSet && language) {
        countriesOptionsList = filterCountriesByLanguage(countries, countriesLanguages);
      } else {
        countriesOptionsList = Object.keys(countries).map((country) => ({ id: country, value: countries[country] }));
      }
      if (multiSelection) {
        setCountriesOptions(countriesOptionsList);
      } else {
        setCountriesOptions([{ id: UNSELECT, value: UNSELECT }, ...countriesOptionsList]);
      }
      if (target.countries.length === 0 && required) {
        const userDefaultCountry = data.contents.defaults?.countries[0];
        setDefaultCountry(countriesLanguages, countriesOptions, userDefaultCountry);
      }
    }
  }

  const setDefaultCountry = (countriesLanguages: Record<string, string>, countriesOptions: { id: string, value: string }[], userDefaultCountry?: string,) => {
    let defaultCountry: { id: string; value: string }[] = [];
    const countriesMap = data.contents.countries.facebook;
    if (countriesOptions.length === 1) {
      defaultCountry = [{ value: countriesOptions[0].value, id: countriesOptions[0].id }];
    } else if (userDefaultCountry && countriesLanguages[userDefaultCountry] === language) {
      defaultCountry = [{ id: userDefaultCountry, value: countriesMap[userDefaultCountry] }];
    } else if (language === 'US') {
      defaultCountry = [{ id: 'US', value: countriesMap['US'] }];
    }
    setTarget((prevTarget) => {
      return {
        ...prevTarget,
        countries: defaultCountry
      }
    });
  }

  const onCountryChange = (e: ClickEventType<HTMLElement>, selectedItem: DropdownListItemProps) => {
    const { id, value } = selectedItem as { id: string, value: string }
    multiSelection ?
      setTarget((prevTarget) => {
        const copyCountries = prevTarget.countries ? [...prevTarget.countries] : [];
        return {
          ...prevTarget,
          countries: [...copyCountries, { id, value }]
        }
      })
      :
      setTarget((prevTarget) => {
        return {
          ...prevTarget,
          countries: [{ id, value }]
        }
      })
  }

  const onCountryDelete = (e: ClickEventType<HTMLElement>, selectedItem: DropdownListItemProps) => {
    const { id } = selectedItem as { id: string, value: string }
    setTarget((prevTarget) => {
      return {
        ...prevTarget,
        countries: prevTarget.countries.filter((country) => country.id !== id)
      };
    });
  };

  const onCountriesSelectAll = (e: ClickEventType<HTMLElement>, selectedItems: DropdownListItemProps[]) => {
    setTarget((prevTarget) => {
      return {
        ...prevTarget,
        countries: (selectedItems as { id: string, value: string }[]).map(({ id, value }) => ({ id, value }))
      };
    });
  };

  const searchPredicate = (query: string | number, item: DropdownListItemProps) => {
    const { id, value } = item as { id: string, value: string }
    const stringQuery = `${query}`;
    return id?.toLocaleLowerCase().includes(stringQuery.toLocaleLowerCase()) || value?.toLocaleLowerCase().includes(stringQuery.toLocaleLowerCase());
  };

  return (
    <Card style={styles.card}>
      <Text type={Text.TEXT_TYPES.PARAGRAPH_L} fontWeight={FontWeight.Medium}>Countries</Text>
      <Dropdown
        items={countriesOptions}
        loading={data.state === 'loading'}
        search
        placeholder="Select Countries"
        onSelectedChange={onCountryChange}
        multiSelection={multiSelection}
        searchPredicate={searchPredicate}
        selectAllLabel="Select All"
        showCheckboxes
        expandableChips
        onValueRemove={onCountryDelete}
        onSelectAllChange={onCountriesSelectAll}
        selectedValues={countries.map(country => country.value)}
      />
    </Card>
  );
}
